@import "src/styles/colors";

.ant-popover {
  width: 300px;

  .ant-popover-inner {
    border: 1px solid $dede;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .1);
  }

  .ant-popover-title {
    padding-top: 19px;
    border: none;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 10px;
  }

  .ant-popover-inner-content {
    padding: 12px;
    border: none;
    box-shadow: none;
    color: $abbey;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
}
