@import "src/styles/breakpoints";
@import "src/styles/colors";

.wiki-main-page {
  position: relative;
  max-width: 1200px;
  margin: 0 20px;
  margin-left: 0;

  .wiki-search-container {
    @include media-breakpoint-down(xs) {
      margin-left: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-left: 20px;
  }
  
  @include media-breakpoint-up(xl) {
    left: -16px;
    margin: 0 auto;
  }

  .wiki-main-page-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px;
    margin-right: 0;
    font-size: 10px;

    @include media-breakpoint-down(xs) {
      margin-right: 0;
      margin-left: 0;
    }

    .wiki-change-log-title-container {
      padding-bottom: 0;

      a {
        display: flex;
        align-items: center;
        font-weight: 700;
        letter-spacing: 2px;
        text-decoration: underline;

        svg {
          margin-right: 8px;
        }
      }
    }
  }

  .wiki-bread-crumb {
    font-size: 10px;
    user-select: none;
  }

  .wiki-category-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 12px;
    animation: fadein 2s;
    
    .wiki-category-card-container {
      display: flex;
      position: relative;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-between;
      width: calc(33.33% - 20px);
      height: 100px;
      margin-bottom: 16px;
      margin-left: 20px;
      padding: 20px;
      transition: all .2s ease-in-out;
      animation: fadein 2s;
      border-radius: 4px;
      background-color: $white;
      cursor: pointer;
      
      &:last-of-type {
        flex-grow: 0;
      }
      
      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
      }
      
      @include media-breakpoint-up(lg) {
        width: calc(25% - 20px);
      }
      
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: $dodger-blue;
      }
      
      .wiki-category-card-title {
        color: $abbey;
        font-size: 11px;
        font-weight: 900;
        letter-spacing: 1.1px;
        text-transform: uppercase;
      }
      
      .wiki-category-card-subtitle {
        padding-bottom: 10px;
        color: $silver-chalice;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 10px;
        text-transform: uppercase;
      }
    }
  }

  .wiki-main-title {
    margin-left: 20px;
    padding: 20px 0;
    color: $ebony-clay;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    user-select: none;

    @include media-breakpoint-down(xs) {
      margin-left: 0;
    }
  }

  .wiki-main-change-log-container {
    margin-left: 20px;
    padding-bottom: 20px;
    animation: fadein 2s;

    @include media-breakpoint-down(xs) {
      margin-left: 0;
    }
  }
}
