@import "src/styles/colors";
@import "src/styles/breakpoints";

.wiki-details-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  padding: 20px;
  border-radius: 3px;
  background: $white;
  color: $ebony-clay;

  .wiki-detatils-content {
    width: 100%;
    padding-top: 15px;
    overflow: hidden;
    word-break: break-word;
    white-space: normal;
  }

  .wiki-details-content-header {
    padding-bottom: 15px;
    border-bottom: 1px solid $border-grey;

    .wiki-details-content-header-title-container {
      display: flex;
      justify-content: space-between;
    
      .wiki-details-content-header-title {
        color: $dodger-blue;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }

    .wiki-details-content-header-tags {
      display: flex;
      padding-top: 10px;

      .wiki-details-content-header-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        height: 22px;
        margin-right: 10px;
        padding: 0 12px;
        border-radius: 10px;
        background-color: $dodger-blue;
        color: $white;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: .64px;
        text-transform: uppercase;

        &:focus {
          outline: none;
        }
      }
    }

    .wiki-details-content-header-last-edit {
      padding-top: 16px;
      color: $abbey;
      font-size: 10px;
      text-transform: uppercase;

      &:before {
        content: "LAST EDIT: ";
        font-weight: 700;
      }
    }

    .wiki-details-content-header-status {
      padding-top: 16px;
      color: $dodger-blue;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;

      &.outdated {
        color: $light-coral;
      }

      &:before {
        content: "LAST STATUS: ";
        color: $abbey;
      }
    }
  }
}

.wiki-details-content-header-language-selector {
  display: flex;
  padding-left: 20px;
  user-select: none;

  .wiki-details-content-header-language {
    padding-left: 4px;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;

    &:not(.selected):hover {
      color: $cornflower-blue;
    }

    &:not(:first-of-type):before {
      content: "/";
      padding-right: 4px;
      color: $ebony-clay;
    }

    &.selected {
      color: $dodger-blue;
    }

    &:focus {
      outline: none;
    }
  }
}
