@import "src/styles/breakpoints";
@import "src/styles/colors";

/* stylelint-disable max-nesting-depth */

.ant-layout * {
  outline: none!important;
}

.table-sortable {
  display: inline-flex;
  flex-direction: column;

  svg {
    margin-top: -8px;
  }
}

.custom-table.ivu-table-wrapper {
  display: flex;
  flex: 1 1 45%;
  width: 100%;
  min-height: 100%;
  border: none;

  &.has-status {
    .ivu-table-header {
      th:first-child {
        span {
          display: none;
        }
      }
    }
  }

  .ivu-table {
    flex: 1;

    &.custom {
      background: $alice-blue;

      table {
        table-layout: fixed !important;

        thead {
          background-color: $white;
        }
      }
    }

    &:before, &:after {
      display: none;
    }

    td {
      border-bottom: none !important;
    }

    th, td {
      outline: none;
    }
  }

  .ivu-table-header {
    th {
      min-width: 60px;
      height: 35px;
      border-bottom-color: $athens-gray;
      background-color: transparent;
      color: $malibu;
      font-size: 10px;
      letter-spacing: .8px;
      text-align: center;
      text-transform: uppercase;
    }
    
    th:first-child span {
      display: block !important;
    }
    
    .ivu-table-cell.contrib {
      color: $turquoise-blue!important;
    }

    thead tr {
      border-bottom: 1px solid $athens-gray;
    }
  }

  .selected {
    background: $alice-blue;
  }

  .clickable {
    cursor: pointer;

    &:not(.selected) {
      &:hover {
        background-color: $alice-blue-light;
      }
    }
  }

  .ivu-table-body {
    &.custom {
      background: $alice-blue;
    }

    tr {
      td {
        height: 42px;
        border-bottom: none !important;
        font-size: 12px;
        text-align: center;

       

        .column-head-title {
          display: none;
          margin-right: 15px;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
        }

        .btn-action {
          width: 25px;
          height: 25px;
          margin: 0 3px;
          padding: 0;
          border: none;
          border-radius: 100%;
          background: $apricot;
          color: $white;
          font-size: 14px;

          &-edit {
            background: $cream-can;
          }
        }

        .disabled {
          background: $dark-grey;
        }
      }

      .expand-icon {
        @include media-breakpoint-up(sm) {
          display: none;
        }
      }

      &.ivu-table-row {
        &.active {
          .expand-icon {
            .ivu-icon {
              transform: rotate(180deg);
            }
          }
        }
      }

      &.expand-row {
        display: none;

        &.active {
          display: block;
        }

        .ivu-table-cell {
          text-align: center;

          .cell-wrap {
            display: block;
          }
        }

        tr {
          th {
            min-width: 60px;
            border-bottom: none;
            font-size: 10px;
            text-transform: uppercase;

            & .ivu-table-cell {
              padding-right: 0;
              padding-left: 0;
            }
          }

          td {
            display: table-cell;
            width: auto;
          }
        }
      }
    }
  }

  .ivu-icon {
    font-size: 16px;

    &.pending {
      color: $cream-can;
    }

    &.published {
      color: $pastel-green;
    }

    &.cancelled,
    &.denied {
      color: $apricot;
    }
  }
}

.table-pager-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  margin-top: 49px;
  margin-bottom: 12px;
  font-size: 12px;
}

.time-warning {
  color: $roman;
  font-weight: 700;
}

.table-pager-button {
  border: 0;
  outline: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    color: $dodger-blue;
  }

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0!important;
    border-radius: 100%;
    background: $dodger-blue;
    color: $white;
  }
}

.table-svg-hover {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    
    svg {
      fill: $grey-light !important;
    }
  }

  &:not(.disabled) svg:hover {
    fill: $dodger-blue !important;
  }
}

.cell-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: 3px;
  }

  svg {
    margin-right: 3px;
  }
}

/* stylelint-enable */
