@import "src/styles/colors";
@import "src/styles/breakpoints";

.current-month-chart-main-container {
  @include media-breakpoint-up(md) {
    width: 40%;
  }

  @include media-breakpoint-up(sm) {
    margin-right: 16px;
  }

  min-width: 280px;
  height: 340px;
  margin-bottom: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: $border-grey;
  background-color: $white;

  .piechart-spinner-container {
    padding: 16px;
  }

  .current-month-chart-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .info-icon-container {
      z-index: 10;
      margin-right: 16px;
      margin-bottom: 4px;
      cursor: pointer;
    }
  }
}

.current-month-chart-piechart-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 250px;
  animation: fadein 2s;
}

.current-month-chart-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 10px;
  color: $ebony-clay;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;

  p {
    .current-month-chart-expected-hours {
      color: $light-cyan;
    }

    .current-month-chart-real-hours {
      color: $light-blue;
    }

    span {
      display: block;
      font-size: 15px;
      font-weight: 900;
    }
  }
}

.current-month-chart-expected-hours-pie {
  display: flex;
  position: absolute;
  width: 338px;
}

.current-month-chart-my-worktime-pie {
  display: flex;
  position: absolute;
  width: 338px;
}
