@import "src/styles/colors";

@mixin bookmarkAccordionTitle {
  display: flex;
  align-items: center;
  padding-left: 20px;
  list-style-type: none;
  font-family: Lato, sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  letter-spacing: .8px;
  line-height: 50px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  .title-text {
    margin-right: auto;
  }

  .title-icon {
    height: 40px;
  }
}

.bookmark-accordion-title-active {
  @include bookmarkAccordionTitle();
  color: $dodger-blue;
  font-weight: 800;
}

.bookmark-accordion-title-inactive {
  @include bookmarkAccordionTitle();
  //border-bottom: 1px solid $border-grey;
  color: $ebony-clay;
  font-weight: 400;
}

@mixin bookmarkAccordionContentItem {
  padding: 20px;

  a {
    font-family: Lato, sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    letter-spacing: .8px;
    line-height: 22px;
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.bookmark-accordion-content {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;

  .bookmark-accordion-content-item {
    @include bookmarkAccordionContentItem();
    width: 310px;
  }

  .bookmark-accordion-content-item-mobile {
    @include bookmarkAccordionContentItem();
    width: 100%;
  }

  .bookmark-description {
    a {
      font-size: inherit;
      text-transform: inherit;
    }
  }
}

.with-bottom-border {
  border-bottom: 1px solid $border-grey;
}
