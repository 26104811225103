@import "src/styles/colors";

.bookmark-category-menu-ul {
  width: 242px;
  margin-bottom: 0;
  padding-left: 0;
}

@mixin bookmarkCategory {
  padding-left: 20px;
  list-style-type: none;
  font-family: Lato, sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  letter-spacing: .8px;
  line-height: 50px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid $border-grey;
  }
}

.bookmark-category-active {
  @include bookmarkCategory();
  color: $dodger-blue;
  font-weight: 800;
}

.bookmark-category-inactive {
  @include bookmarkCategory();
  color: $ebony-clay;
  font-weight: 400;
}
