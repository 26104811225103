@import "src/styles/breakpoints";
@import "src/styles/colors";

.colleagues-title {
  max-width: 1200px;
  margin: 16px;
  font-size: 10px;
  font-weight: 700;

  @include media-breakpoint-up(xl) {
    margin: 16px auto;
  }
}

.dropdown-filter-title {
  margin-left: 6px;

  .dropdown-filter-placeholder {
    display: flex;
    width: 96%;
    overflow: hidden;
    white-space: nowrap;

    .dropdown-filter-placeholder-title {
      margin-left: 6px;
      opacity: .5;
    }

    div {
      display: flex;
      align-items: center;
    }
    
    .selected-value {
      margin: 0 2px;
      padding: 3px 7px;
      border-radius: 4px;
      background: $dodger-blue;
      color: $white;
    }
  }
}

.colleagues-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 16px;
  animation: fadein 2s;
  border: 1px solid $border-grey;
  border-radius: 4px;
  background-color: $white;

  @include media-breakpoint-up(xl) {
    margin: 0 auto;
  }

  .spin-position {
    min-height: 300px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .header-container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .colleagues-body-container {
    width: 100%;
    min-height: calc(100vh - 200px);
    padding: 16px 22px;
    
    .colleagues-separator {
      position: relative;
      margin-bottom: 16px;
      padding: 8px 0;
      
      hr {
        width: 100%;
        height: 1px;
        border: none;
        background: $gainsboro;
        box-shadow: none;
      }
      
      .colleagues-separator-title {
        position: absolute;
        top: 8px;
        height: 16px;
        margin-left: 42px;
        padding: 0 20px;
        background: $white;
        color: $silver-chalice;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
      }
    }

    .no-result {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 200px;

      svg {
        margin-bottom: 10px;
        opacity: .6;
      }
    }
  }

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    margin-right: 20px;
    margin-left: 20px;
    border-bottom: 1px solid black;
    font-size: 11px;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
      width: 380px!important;
      padding-bottom: 6px!important;
      padding-left: 6px!important;
    }

    svg {
      margin-right: 7px;
    }
  }
}

.ant-dropdown {
  display: flex;
  flex-direction: row;
  min-width: 0;

  &.ant-dropdown-placement-bottomRight {
    .ant-dropdown-menu.menu-style {
      float: right;
    }
  }

  .ant-dropdown-menu.menu-style {
    display: flex!important;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100vw - 36px);
    padding: 35px 30px!important;
    background-color: $white;

    @include media-breakpoint-up(sm) {
      width: 380px!important;
    }

    @include media-breakpoint-up(xs) {
      flex-direction: row;
    }

    .dropdown-clear-all {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      justify-content: center;
      width: 100%;
      height: 40px;
      padding-bottom: 15px;

      .dropdown-clear-all-button {
        padding: 3px 25px;
        border: 1.5px solid $dodger-blue;
        border-radius: 36px;
        background-color: $dodger-blue;
        color: $white;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 1px;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
    }

    .filter-title {
      color: $ebony-clay;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 1.11px;
      text-transform: uppercase;
    }

    /* stylelint-disable max-nesting-depth */

    .ant-dropdown-menu-item {
      flex: 1;
      
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        
        .ant-checkbox-group-item {
          margin-right: 8px;
          margin-bottom: 20px;
          font-size: 12px;

          &:first-of-type {
            margin-top: 15px;
          }
        }
      }

      &:hover {
        background-color: transparent!important;
      }

      .ant-dropdown-menu-item-active {
        background-color: transparent!important;
      }
    }

    /* stylelint-enable */

  }
}

.ant-popover-content {
  .ant-popover-inner-content {
    padding: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);

    .colleague-modal-wrapper {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      padding-top: 12px;

      .colleague-modal-close {
        position: absolute;
        top: 6px;
        right: 8px;
      }
      
      @include media-breakpoint-up(sm) {
        width: 250px!important;
      }
      
      .ant-avatar.ant-avatar-circle {
        font-size: 40px!important
      }

      .colleague-modal-name {
        padding: 10px 0;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      .colleague-modal-role {
        padding-bottom: 10px;
        font-size: 9px;
        font-weight: 700;
      }

      .colleague-modal-status {
        padding-bottom: 10px;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 1.11px;
        text-transform: uppercase;
      }
    }
  }

  .ant-popover-arrow {
    display: none!important;
  }
}

.ant-collapse.ant-collapse-icon-position-left {
  width: 100%;
  margin-bottom: 16px!important;
  border: none!important;
  background-color: $white;

  .ant-collapse-item-active {
    .colleague-card-container {
      border-bottom-right-radius: 0!important;
      border-bottom-left-radius: 0!important;
    }
  }

  .ant-collapse-header {
    padding: 0!important;

    .colleague-card-container {
      margin-bottom: 0!important;
    }
  }

  .ant-collapse-content.ant-collapse-content-active {
    border: none!important;

    .ant-collapse-content-box {
      padding: 0!important;

      .colleague-modal-info-box {
        margin-top: 0;
      }
    }
  }

  .ant-collapse-item.ant-collapse-no-arrow {
    border: none!important;
  }
}

.colleague-modal-info-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 70px;
  margin-top: 10px;
  padding: 0 16px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: $ebony-clay;
  color: $white;

  .colleague-modal-info-box-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.phone {
      padding-bottom: 10px;
    }

    .colleague-modal-info-box-title {
      color: $dodger-blue;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      text-transform: uppercase;
    }

    .colleague-modal-info-box-value {
      color: $white;
      font-size: 10px;
      font-weight: 700;
      word-wrap: break-word;
    }
  }
}

.colleague-cards-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 10px;

  .colleague-card-container {
    display: flex;
    align-items: center;
    width: 33.33%;
    height: 136px;
    margin-bottom: 16px;
    padding: 0 10px;
    transition: all .2s ease-in-out;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      border-color: $whisper;
      box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    }

    &:focus {
      outline: none;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      border-color: $whisper;
      box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
    }

    @include media-breakpoint-up(lg) {
      width: 25%;
    }

    .colleague-avatar-container {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
      
    .colleague-card-data-container {
      display: flex;
      flex: 2;
      flex-direction: column;
      padding-left: 20px;

      .colleague-card-data-name {
        color: $abbey;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .colleague-card-data-role {
        padding-bottom: 5px;
        color: $ebony-clay;
        font-size: 9px;
        font-weight: 700;
      }

      .colleague-card-data-status {
        font-size: 9px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
}
