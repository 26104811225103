@import "src/styles/colors";
@import "src/styles/breakpoints";

.wiki-magic-table-container {
  padding: 12px;
  background-color: $white;

  @include media-breakpoint-up(sm) {
    padding: 0;
  }

  tr {
    height: 55px;
  }

  .ivu-table-row {
    height: 55px;
    border-bottom: none;

    @include media-breakpoint-up(sm) {
      border-bottom: 1px solid $athens-gray;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
