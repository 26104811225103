@import "src/styles/colors";

.wiki-details-header-container {
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 10px;
  margin-left: 20px;
  color: $ebony-clay;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.08px;

  span {
    user-select: none;
  }
}

.wiki-details-panel-container {
  .wiki-details-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
    border-bottom: .5px solid $gainsboro;
    font-size: 11px;
    line-height: 15px;
    user-select: none;

    .wiki-details-link-container {
      padding-left: 30px;

      .wiki-details-date {
        text-transform: uppercase;
        user-select: text;
      }

      .edited-by {
        max-width: 200px;
        color: $dodger-blue;
        text-decoration: underline;
        cursor: default;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
