@import "src/styles/colors";
@import "src/styles/breakpoints";

.progress-bar-container {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  max-width: 1200px;
  min-height: 116px;
  margin-bottom: 16px;
  padding-bottom: 32px;
  animation: fadein 2s;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: $border-grey;
  background: $white;

  .progress-bar-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info-icon-container {
      margin-right: 16px;
      margin-bottom: 4px;
      cursor: pointer;
    }
  }

  .progress-bar-header {
    @include media-breakpoint-up(sm) {
      padding: 12px 20px;
    }

    display: flex;
    width: 100%;
    animation: fadein 2s;

    .progress-bar-header-icon {
      width: 16px;
      height: 18px;
      margin-right: 10px;
    }

    .progress-bar-header-title {
      font-size: 10px;
      font-weight: 700;
      letter-spacing: .8px;
    }
  }

  .progress-bar {
    margin-bottom: 28px;
    padding: 0 16px;
    
    @include media-breakpoint-up(sm) {
      padding: 0 36px;
    }
  }

  .progress-bar-body-workhours-title {
    font-size: 10px;
  }

  .progress-bar-body {
    @include media-breakpoint-up(xs) {
      flex-direction: row;
      padding: 16px 0;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    padding: 12px 0;
    animation: fadein 2s;

    .progress-bar-workhours-mobile {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
      font-size: 15px;
      font-weight: 700;

      @include media-breakpoint-up(xs) {
        display: none;
      }
    }

    .progress-bar-body-workhours {
      display: none;
      cursor: pointer;

      @include media-breakpoint-up(xs) {
        display: flex;
      }

      .progress-bar-body-workhours-value {
        font-size: 15px;
        font-weight: 700;
      }
    }

    .progress-bar-body-progress-bar {
      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
      position: relative;
      width: 100%;
      height: 17px;
      margin: 0 10px;
      background-color: $white-smoke;

      .progress-bar-body-progress-bar-current {
        position: absolute;
        height: 100%;
      }

      .progress-bar-body-progress-bar-expected {
        position: absolute;
        top: -9px;
        width: 2px;
        height: 38px;
        opacity: .6;
        background: $storm-grey; 
      }

      .right {
        margin-left: -5px !important;
        
        @include media-breakpoint-up(xs) {
          margin-left: -16px !important;
        }
      }

      .left {
        margin-left: -38px !important;
        text-align: right;
        
        @include media-breakpoint-up(xs) {
          margin-left: -16px !important;
          text-align: left;
        }
      }

      .progress-bar-body-progress-bar-title-container {
        display: flex;
        position: absolute;
        top: 37px;
        flex-direction: column;
        margin-left: -16px;
        font-size: 10px;

        .progress-bar-body-progress-bar-title-date {
          font-weight: 700;
          line-height: 0;
          text-transform: uppercase;
        }

        .progress-bar-body-progress-bar-title-time {
          margin-top: 12px;
          line-height: 0;
          white-space: nowrap;
        }
      }
    }
  }

  .progressbar-spinner-container {
    display: flex;
    padding: 16px;
  }

  .yellowBackground {
    background-color: $ajjaj-yellow !important;
  }

  .yellow {
    color: $ajjaj-yellow !important;
  }

  .greenBackground {
    background-color: $pastel-green !important;
  }

  .green {
    color: $pastel-green !important;
  }

  .redBackground {
    background-color: $apricot !important;
  }

  .red {
    color: $apricot !important;
  }
}

.progress-bar-chart-container {
  margin-top: 30px;
}

.popover-content-list {
  padding: 0;
  list-style-type: none;
}

@keyframes fadein {
  from { opacity: 0; }

  to   { opacity: 1; }
}
