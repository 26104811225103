@import "src/styles/colors";

.login-container {
  width: 300px;
  height: 100%;
  margin: auto;
  margin-bottom: 60px;
  overflow: hidden;
  border: 1px solid $whisper;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 36px;
    border-radius: 0!important;
    background-color: $dodger-blue!important;
    color: $white!important;
    font-size: 15px!important;
    text-transform: capitalize!important;
  }

  .login-logo {
    display: block;
    width: 300px;
    height: 117px;
    padding: 20px 50px;
    background-color: $malibu;
  }
}
