@import "src/styles/colors";

.header-status-select-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  height: 36px;
  margin: 3px 0;
  padding: 0 15px;
  transition: background-color .3s ease;
  border-radius: 16px!important;
  background: $whisper;
  color: $abbey;
  font-size: 10px;
  line-height: 36px;
  text-align: left;
  text-transform: uppercase;
}

.header-status-selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 4px;
}

.header-status-dropdown {
  display: flex;
  align-items: center;
  height: 60px;
  
  .ant-select.ant-select-enabled.ant-select-no-arrow .ant-select-selection.ant-select-selection--single {
    outline: none!important;
    box-shadow: none!important;
  }
 
  .ant-select-selection__rendered {
    display: flex;
    top: 3px;
    justify-content: space-between;
  }
  
  .ant-select-selection.ant-select-selection--single {
    width: 180px;
    height: 36px;
    margin: 3px 0 3px 12px;
    padding: 0;
    border-radius: 16px!important;
    background: $athens-gray;
    color: $abbey;
    font-size: 10px;
    line-height: 36px;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .ant-select-selection {  
    border: none!important;
    outline: none!important;
  }

  .ant-select.ant-select-focused {
    border: none!important;
    outline: none!important;
  
    &:focus {
      border: none!important;
      outline: none!important;
    }
  }
 
  .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border: none!important;
  }
  
  .ant-select-selection-selected-value {
    width: 100%!important;
  }
}

.header-status-dropdown-label {
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: $abbey;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 10px;
  text-transform: uppercase;
  pointer-events: none;
}

.header-status-menu-label {
  width: 30px;
  height: 100%;
}

.header-status-circle {
  width: 14px;
  height: 14px;
  border-radius: 100%;
}

.working-from-home-message-required {
  margin-bottom: 10px;
  color: $silver-chalice;
  font-size: 10px;
  font-weight: 700;
  text-align: left;

  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    color: $pomegranate;
    font-family: SimSun;
    font-size: 12px;
    line-height: 1;
  }
}

.ant-modal-body textarea {
  background: $athens-gray;
  font-size: 10px;
}

.header-status-select-dropdown {
  .ant-select-dropdown-menu-item-active .header-status-select-option:hover {
    background: $iron;
  }

  &.ant-select-dropdown.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft {
    overflow: hidden;
    border-radius: 28px;
  }

  .ant-select-dropdown-menu {
    max-height: 1000px!important;
  }
  
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: $white!important;
  }
}
