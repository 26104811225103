@import "src/styles/breakpoints";
@import "src/styles/colors";

.page-absence {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  font-size: 10px;

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  .absence-page-title {
    padding: 16px 0;
    font-weight: 700;
    text-transform: uppercase;
  }

  .absence-page-container {
    min-height: 300px;
    animation: fadein 2s;
    border: 1px solid $hawkes-blue;
    border-radius: 4px;
    background: $white;
  }

  .absence-page-spinner-container {
    margin: 20px;
  }

  .absence-page-summary {
    display: flex;
    align-items: flex-end;
    padding: 0 20px 15px;

    .absence-page-summary-indicator {
      margin-right: 6px;
    }

    .absence-page-summary-title {
      width: 60px;
      margin-right: 10px;
      color: $silver-chalice;
      font-weight: 700;
      text-transform: uppercase;
    }

    .absence-page-number {
      font-size: 12px!important;
      font-weight: 700;
    }

    .absence-page-green {
      color: $pastel-green;
    }

    .absence-page-grey {
      color: $silver-chalice;
    }

    .absence-page-yellow {
      color: $casablanca;
    }
  }

  .absence-page-button {
    display: flex;
    justify-content: flex-end;
    padding: 6px 22px 10px;
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
  }

  .absence-page-table-container {
    @include media-breakpoint-down(xs) {
      padding: 12px;
    }
  }

  .absence-page-summary-circles {
    display: none;
    align-items: center;
    height: 18px;
    margin-right: 10px;
    
    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  .absence-page-ball {
    width: 10px;
    height: 10px;
    margin: 0 2px;
    border-radius: 100%;
    background-color: $silver-chalice;

    &.active-green {
      background-color: $pastel-green;
    }

    &.active-yellow {
      background-color: $casablanca;
    }
  }

  .absence-page-action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin: 0 3px;
    border-radius: 100%;
    background: $dark-grey;
    cursor: not-allowed;

    svg {
      margin-right: -1px;
    }

    &:not(.disabled) {
      cursor: pointer;

      &.yellow {
        background-color: $casablanca;
      }

      &.red {
        background-color: $light-coral;
      }
    }
  }

}

.absence-modal {
  .ant-form .ant-row .ant-col:nth-of-type(2n-1) {
    flex: none!important;
  }

  .ant-form .ant-row .ant-col:nth-of-type(2n) {
    flex: none!important;
  }

  .ant-row.ant-form-item {
    display: block!important;
  }

  .ant-form.ant-row {
    @media (min-width: 480px) {
      display: block!important;
    }
  }
}

.ant-calendar-picker, .ant-form-item-children {
  width: 100%!important;
}

.ant-input, .ant-input-number-input, .ant-select-selection {
  background-color: $athens-gray!important;
}

.ant-calendar-range-picker-input {
  width: 49%!important;

  &::placeholder {
    margin-left: -30px!important;
    text-align: left!important;
  }
}

.ant-col.ant-form-item-label label {
  color: $silver-chalice!important;
  font-size: 12px!important;
  font-weight: 700;
}

.ant-row.ant-form-item {
  text-align: left!important;
}

.ant-radio-group {
  width: 100%!important;
  text-align: center!important;
}
