@import "src/styles/breakpoints";
@import "src/styles/colors";

.events-main-container {
  animation: fadein 2s;

  .events-spinner-container {
    display: flex;
    padding: 16px;
  }

  .events-today-date {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .events-data-container * {
    font-size: 12px;
  }

  .events-data-container {
    .no-events {
      margin: 20px;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .event-list {
      padding: 20px;
    
    } 
  }
}

.event-list-item {
  @include media-breakpoint-up(sm) {
    justify-content: flex-start;
  }

  &.declined {
    text-decoration: line-through;
  }

  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  list-style-type: none;
  border-bottom: 1px solid $border-grey;

  .event-time {
    min-width: 75px;
    margin-right: 10px;
    color: $dodger-blue;
    font-weight: 700;
  }

  .event-details {
    @include media-breakpoint-up(sm) {
      min-width: 200px;
    }

    .event-name {
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      display: flex;
      flex-direction: row-reverse ;
      font-weight: 700;

      .event-hangouts-link {
        @include media-breakpoint-up(sm) {
          margin-left: 10px;
        }

        display: block;
        width: 30px;
        margin-right: 10px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
    }
  }

  .event-details * {
    @include media-breakpoint-up(sm) {
      justify-content: flex-start;
      text-align: left;
    }

    text-align: right;  
  }
}
