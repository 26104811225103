@import "src/styles/breakpoints";
@import "src/styles/colors";

.wiki-search-input-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  min-width: 300px;
  padding: 20px;

  @include media-breakpoint-up(sm) {
    width: 300px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .wiki-search-input {
    display: flex;
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0 !important;
    font-size: 12px;

    &:focus {
      outline: none;
    }
    
    &::placeholder {
      padding-left: 6px;
      color: $abbey!important;
      font-size: 11px;
    }
  }

  .wiki-search-button {
    all: unset;
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
  }
}
