@import "src/styles/colors";
@import "src/styles/breakpoints";

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background: $white;
}

.app-header-mobile-menu {
  display: flex;
  cursor: pointer;
}

.app-header-mobile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 45px;
  height: 60px;
  padding: 0;
  border: none;
  outline: none;
  background: $white;
  cursor: pointer;
	
  &.blue {
    justify-content: center;
    width: 60px;
    background: $dodger-blue;

    @media (max-width: 370px) {
      display: none;
    }

    svg {
      height: 75%;
    }
  }

  &.menu {
    background: $white;
  }
}
