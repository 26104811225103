@import "src/styles/colors";

.profile-picture-modal {
  .ant-btn:first-of-type {
    display: none;
  }

  input:focus {
    outline: none
  }

  .uppy-Dashboard-inner {
    min-height: unset;
  }

  .uppy-Dashboard-innerWrap {
    flex-direction: column;

    .uppy-DashboardContent-back {
      display: none;
    }

    .uppy-DashboardAddFiles {
      flex-direction: row;
    }
  }

  .uppy-Dashboard-files .uppy-u-reset.uppy-DashboardItem {
    display: flex;
    position: relative;
    flex-direction: column-reverse;
    justify-content: center;
    height: 100%;
    padding: 10px!important;
    border-bottom: 0!important; 

    .uppy-DashboardItem-fileInfoAndButtons {
      z-index: 9999;
      flex-grow: unset;
      margin-bottom: -14px!important;
      padding-left: 130px!important;
      cursor: pointer;

      .uppy-u-reset.uppy-DashboardItem-action.uppy-DashboardItem-action--remove {
        width: unset;
        height: unset;

        .UppyIcon {
          width: 20px!important;
          height: 20px!important;
        }
      }

      .uppy-DashboardItem-fileInfo {
        display: none!important;
      }
    }

    .uppy-DashboardItem-preview {
      width: 160px!important;
      height: 160px!important;
      border-radius: 0!important;

      .uppy-DashboardItem-previewInnerWrap {
        border-radius: 50%!important;
      }
    }
  }
}
