@import "src/styles/colors";

.chart-title {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 12px 20px 22px;
  animation: fadein 2s;
  color: $midnight-express;
  font-family: Lato, sans-serif;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  text-transform: uppercase;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
}

.chart-title-text {
  margin-left: 10px;
}
