@import "src/styles/breakpoints";
@import "src/styles/colors";

.wiki-topics-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 240px;
  height: 50px;
  padding-top: 0;
  border-radius: 3px;
  background-color: $white;
  
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: unset;
    margin-bottom: 16px;
    padding-top: 25px;
  }

  .wiki-topics-title {
    display: flex;
    justify-content: center;
    padding-left: 22px;
    color: $ebony-clay;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1.08px;
    user-select: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
}

.wiki-topics-topic-container {
  width: 100%;
  @include media-breakpoint-up(sm) {
    padding-top: 10px;
  }

  .wiki-topics-topic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50px;
    padding: 10px 10px 10px 22px;
    border-bottom: .5px solid #D9D9D9;
    color: $ebony-clay;
    font-size: 13px;
    cursor: pointer;
    user-select: none;

    &:last-of-type {
      border: none;
    }

    &:focus {
      outline: none;
    }

    .wiki-topics-subtopic {
      padding: 5px 5px 5px 22px;
      border: none;
      color: $ebony-clay;

      &:last-of-type {
        padding-bottom: 0;
      }

      &:focus {
        outline: none;
      }  
    } 
  }

  .active {
    color: $dodger-blue!important;
  }
}
