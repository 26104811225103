$pastel-green: #67E49C;
$dodger-blue: var(--dodger-blue);
$dodger-blue-light: var(--dodger-blue-light);
$athens-gray: #F3F4F8;
$silver-chalice: #B2B2B2;
$turquoise-blue: #62E2E3;
$malibu: var(--malibu);
$apricot: #F08887;
$cream-can: #F3B657;
$ebony-clay: #262D37;
$abbey: #515356;
$sunglo: #E46A67;
$east-bay:#495060;
$very-soft-blue: #6796E4;
$border-grey: #E9EAEC;
$light-cyan: #6BF7F8;
$light-blue:#6796E4;
$white:#FFFFFF;
$white-light:#FFFFFFBF;
$midnight-express: #1C2438;
$white-smoke: #F3F3F3;
$storm-grey: #7F8184;
$whisper: #F6F7FA;
$pomegranate: #ED3F14;
$iron: #D3D4D8;
$black: #000000;
$grey: #808080;
$grey-light: #CCCCCC;
$dark-grey: #AAAAAA;
$alabaster: #F7F7F7;
$deep-sky-blue: var(--deep-sky-blue);
$radical-red: #FF4D4F;
$cornflower-blue: #58ADFC;
$light-coral: #F08887;
$gainsboro: #D9D9D9;
$alice-blue: #F1F8FE;
$alice-blue-light: #FDFEFF;
$roman: #E46A67;
$casablanca: #F3B657;
$maya-blue: #6FB7FB;
$maya-blue-light: rgba(113, 182, 249, .25);
$cheppers-blue: $malibu;
$hawkes-blue: #DDDEE1;
$none: none;
$dede: #D3D3D3;
$ajjaj-yellow: #FFC977;
