@import "src/styles/colors";
@import "src/styles/breakpoints";

.profile-card-input-container {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  margin-bottom: 16px;

  .react-tel-input {
    position: relative!important;
    padding-left: 0!important;

    &:not(.invalid) {
      &:hover,  &:focus, &:active, &:focus-within {
        border-color: $cornflower-blue!important;
      }
    }

    .flag-dropdown {
      border: none!important;
    }

    .selected-flag {
      position: unset!important;
    }

    .country-list {
      font-size: 14px!important;
    }
  }

  .react-tel-input .flag-dropdown:hover .selected-flag, .react-tel-input .flag-dropdown:focus .selected-flag {
    background-color: $white-smoke!important;
  }

  @include media-breakpoint-up(sm) {
    &:nth-child(1), &:nth-child(3) {
      margin-right: 16px;
    }
  }

  .profile-car-input-label-wrong {
    position: absolute;
    top: 10px;
    right: 0;
    color: $light-coral;
    font-size: 10px;
  }

  .profile-card-input-label {
    color: $ebony-clay;
    font-size: 10px;
    letter-spacing: 1.11px;
    text-align: left;
  }

  .ant-input, .ant-input-disabled, .ant-select-selection, .react-tel-input {
    height: 40px;
    margin: 10px;
  }

  .ant-input, .ant-select-selection, .react-tel-input {
    display: flex;
    align-items: center;
    min-width: 230px;
    margin: 10px 0 0 0px!important;
    padding-left: 5px;
    border: 1px solid $gainsboro;
    border-radius: 3px;
    outline: 0;
    background: $white!important;
    color: $ebony-clay;
    font-size: 10px;

    .form-control {
      width: 100%;
      height: 100%;
      border: none;
      font-size: 11px;
    }

    &.invalid {
      border: 1px solid $light-coral;
    }

    &.no-border {
      border-color: $gainsboro;
    }
  }

  .ant-select-open .ant-select-selection {
    box-shadow: none!important;
  }

  .ant-select-selection__rendered {
    margin-left: 0!important;
  }

  .ant-select-selection__placeholder, .ant-select-search__field__placeholder {
    display: block!important;
    position: absolute;
    right: unset!important;
    overflow: unset!important;
  }
}

.profile-card-input-label, .profile-card-subheader {
  text-transform: uppercase;
}

.profile-card-input-label {
  color: $grey;
  letter-spacing: 2px;
}
