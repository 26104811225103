:root {
  --dodger-blue: #b680ec;
  --dodger-blue-light: #b680ecd1;
  --malibu: #6c00d9;
  --deep-sky-blue: #b680ec;
}

body {
  height: auto;
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
