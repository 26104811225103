@import "src/styles/breakpoints";
@import "src/styles/colors";

.wiki-autocomplete-input-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  min-width: 300px;
  padding: 20px;

  @include media-breakpoint-up(sm) {
    width: 300px;
  }

  .ant-select-auto-complete {
    width: 100%!important;
  }

  .ant-select-search__field {
    display: flex!important;
    width: 100%!important;
    height: 30px!important;
    border: none!important;
    border-bottom: 1px solid #000!important;
    border-radius: 0 !important;
    background: $white!important;
    font-size: 12px!important;
    
    @include media-breakpoint-up(sm) {
      width: 260px!important;
    }
  }

  .autocomplete-search-button {
    all: unset;
    position: absolute;
    z-index: 2;
    top: 30px;
    right: 20px;
    cursor: pointer;
  }

  .ant-select-selection__placeholder {
    z-index: 10;
    margin-left: 0!important;
    padding-left: 6px;
    color: $abbey!important;
    font-size: 11px;
  }
  
  .ant-select-selection--single {
    cursor: text!important;
  }
  
  .ant-select-search--inline {
    background: $white!important;
  }
}
