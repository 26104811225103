@import "src/styles/breakpoints";

.page-status-and-worktime {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;

  @include media-breakpoint-up(xl) {
    padding: 16px 0;
  }
}
