@import "src/styles/colors";
@import "src/styles/breakpoints";

.worktime-chart-fadein-container {
  display: flex;
  justify-content: center;
  animation: fadein 2s;
}

.worktime-chart-barchart-container-wide {
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 0 100px;

  @include media-breakpoint-down(md) {
    padding: 0 60px;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }

  .worktime-chart-weekly-barchart-container-wide:nth-child(1) {
    margin-right: 10px;
  }

  .worktime-chart-weekly-barchart-container-wide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 300px;
    margin-top: 16px;

    .worktime-chart-label {
      display: flex;
      margin-top: -25px;
      margin-left: 5px;

      .worktime-label-item {
        flex-direction: column;
        width: 50px;
        font-size: 10px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}

.worktime-chart-main-container {
  @include media-breakpoint-up(sm) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: $border-grey;
  background-color: $white;

  .chart-title {
    padding-bottom: 12px;
  }

  .worktime-chart-barchart-container {
    @include media-breakpoint-up(xs) {
      flex: 1;
      flex-direction: row;
      padding: 0 100px;
    }

    @include media-breakpoint-up(sm) {
      display: none;
    }

    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-around;
    width: 220px;

    .worktime-chart-weekly-barchart-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 220px;
      margin-top: 16px;
    }

    .worktime-chart-weekly-barchart-container:nth-child(1) {
      margin-right: -3px;
    }
  }

  .barchart-spinner-container {
    @include media-breakpoint-up(sm) {
      height: 320px;
    }
    height: 596px;
    padding: 16px;
  }
}

.worktime-chart-date-label {
  box-sizing: border-box;
  padding: 0;
  color: $east-bay;
  font-family: Lato, sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.worktime-chart-label {
  display: flex;
  margin-top: -25px;
  margin-left: 16px;

  .worktime-label-item {
    flex-direction: column;
    width: 30px;
    margin-right: 10px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
  }

  .name-of-day {
    margin-bottom: 8px;
    color: $silver-chalice;
    font-weight: 700;
    text-transform: uppercase;
  }

  .time- {
    &worktime {
      color: $very-soft-blue;
      font-weight: 700;

      &.wasted {
        color: $sunglo;
      }
    }

    &pm-logged-time {
      color: $pastel-green;
      font-weight: 700;

      &.wasted {
        color: $sunglo;
      }
    }
  }

  .homeoffice {
    font-weight: 700;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
