@import "src/styles/breakpoints";
@import "src/styles/colors";

.profile-page-container {
  display: flex;
  max-width: 1200px;
  margin: 16px;
  padding: 16px;
  animation: fadein 2s;
  background: $white;

  @include media-breakpoint-up(xl) {
    margin: 0 auto;
    margin-top: 16px;
  }

  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
  }

  .profile-spinner-container {
    height: 400px;
    margin: 8px;
  }

  .custom-table.ivu-table-wrapper {
    min-height: unset!important;
  }

  .profile-page-main-title {
    padding: 20px 0;
    padding-left: 16px;
    color: $ebony-clay;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2.4px;
    line-height: 10px;
  }

  .profile-required-data {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    background-color: $white;

    .ant-collapse-item {
      border-radius: 5px!important;
      border-color: $whisper;
      box-shadow: 0 1px 6px rgba(0, 0, 0, .2);

      .anticon.anticon-right.ant-collapse-arrow {
        display: none;
      }

      .profile-page-main-title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px!important;
        color: $dodger-blue;
      }
    }
    
    @include media-breakpoint-up(sm) {
      padding: 16px;
      border: none;
      background-color: $athens-gray;
    }

    .button-container {
      display: flex;
      justify-content: center;
      padding: 16px;

      @include media-breakpoint-up(sm) {
        justify-content: flex-start;
      }
    }

    .ant-btn {
      max-width: 200px;
      border: 1px solid $radical-red!important;
      background-color: transparent!important;
      color: $radical-red!important;

      &:focus {
        outline: 0!important;
        box-shadow: none!important;
      }
    }

    .profile-card-photo {
      display: flex;
      position: relative;
      justify-content: center;

      .avatar-container {
        border: none;
      }

      .add-photo-container {
        display: flex;
        position: absolute;
        right: 20px;
        bottom: 20px;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $dodger-blue;
        cursor: pointer;
        
        @include media-breakpoint-up(sm) {
          position: absolute;
          right: 12px!important;
          bottom: 12px!important;
        }

        &:focus {
          outline: none;
        }

        .add-photo {          
          width: 20px;
          height: 20px;
        }
      }
    }

    .profile-required-data-group {
      padding-bottom: 16px;
      padding-left: 16px;

      &.collapse {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        margin-right: 16px;
        margin-left: 16px;
        padding-bottom: 0;
        padding-left: 0;
        border-top: 1px solid $gainsboro;
      }

      .profile-required-data-group-field {
        margin-bottom: 4px;
        color: $silver-chalice;
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 1px;
      }
      
      .profile-required-data-group-value {
        color: $ebony-clay;
        font-size: 12px;
      }
    }
  }

  .profile-changable-data {
    width: 760px;

    @include media-breakpoint-up(sm) {
      margin-left: 16px;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 auto;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    .button-container {
      display: flex;
      justify-content: center;
      
      @include media-breakpoint-up(sm) {
        justify-content: flex-end;
      }

      .ant-btn {
        width: 150px;
      }
    }
    
    .profile-page-main-title {
      padding-left: 0!important;
    }

    .add-new-button {
      display: flex;
      align-items: center;
      max-width: 150px;
      height: 30px;
      padding: 10px 0;
      color: $dodger-blue;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 2px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
      
      svg {
        margin-right: 12px;
      }
    }
  }
}

.profile-page-inputs {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.profile-collapse-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile-card-photo {
    position: relative;
    flex: 1;
    padding: 12px 0;

    .avatar-container {
      border: none;
    }

    .add-photo-container {
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $dodger-blue;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      .add-photo {          
        width: 20px;
        height: 20px;
      }
    }
  }
  
  .profile-page-name {
    color: $ebony-clay;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 2.4px;
    line-height: 10px;
    text-transform: uppercase;
  }
}
