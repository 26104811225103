@import "src/styles/colors";
@import "src/styles/breakpoints";

.status-and-worktime-card {
  animation: fadein 2s;
  border: 1px solid $border-grey;
  border-radius: 4px;
  background: $white;
}

.status-and-worktime-table-container {
  padding: 16px 10px;

  .magictable-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    tr {
      &:focus {
        svg {
          transform: rotate(30deg);
        }
      }
    }
    
    .ant-collapse > .ant-collapse-item {
      border: 0!important;
    }
  }
}

#status-and-wortime-range-container {
  width: 280px;
  padding: 0 10px;

  .ant-calendar-picker {
    .ant-input {
      background-color: $white!important;
    }
  }
}

.ant-calendar {
  width: 90%!important;

  .ant-calendar-panel {
    width: 100%!important;
  }
}

.ant-calendar-range {
  width: 95%;

  .ant-calendar-panel {
    width: 100%;

    .ant-calendar-date-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
    }
  }
}
