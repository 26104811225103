@import "src/styles/colors";

.sider-menu-container {
  border-right: none!important;
  background: $ebony-clay!important;
  color: $east-bay;

  &.ant-menu-inline-collapsed {
    width: 118px!important;

    .menu-item-elements {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ant-drawer-content {
  background-color: $ebony-clay!important;
}

.sider-menu-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  background: $cheppers-blue;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    height: 60%;
  }
}

.menu-item-elements {
  display: flex!important;
  align-items: center;
  margin-top: 0!important;
  margin-bottom: 0!important;
  padding: 12px 20px!important;
  background-color: transparent!important;
  color: $white!important;
  font-size: 10px!important;
  font-weight: 700!important;
  letter-spacing: 1.2px!important;
  text-transform: uppercase!important;

  svg {
    fill: $white!important;
  }

  &.ant-menu-item-selected {
    color: $dodger-blue!important;

    svg {
      fill: $dodger-blue!important;
    }
  }

  &:hover:not(.ant-menu-item-selected) {
    color: $malibu!important;

    svg {
      fill: $malibu!important;
    }
  }
}
