@import "src/styles/colors";

.app-layout {
  display: flex;
}

.app-sider {
  border: none;
  background: $ebony-clay!important;
  color: $east-bay;
}

.app-container {
  flex: 1;
}

.app-layout-header {
  height: 60px!important;
  margin: 0;
  padding: 0!important;
}

.app-trigger {
  background: $ebony-clay!important;
}

.status-change-confirmation-message {
  padding-bottom: 20px;
  font-size: 11px;
}

.status-indicator {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
