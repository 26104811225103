@import "src/styles/colors";

.bookmarks-container-main {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .side-menu {
    flex-basis: 242px;
    border-right: 1px solid $border-grey;
  }

  .content {
    flex-grow: 1;
  }
}
