.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  font-size: 16px;

  svg {
    margin-bottom: 20px;
  }
}
