@import "src/styles/colors";
@import "src/styles/breakpoints";

.wiki-change-log-page {
  max-width: 1200px;
  margin: 0 20px;
  padding: 16px 0;

  @include media-breakpoint-down(xs) {
    margin-left: 20px;
  }

  @include media-breakpoint-up(xl) {
    margin: 0 auto;
  }
}

.wiki-change-log-title-container {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  color: $abbey;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  user-select: none;

  a {
    display: flex;
    align-items: center;
    color: $abbey;

    &:hover {
      color: $cornflower-blue;
    }
  }
}

.wiki-bread-crumb {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  color: $ebony-clay;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;

  span:not(.ant-breadcrumb-separator) {
    display: flex;
    align-items: center;
  }

  .ant-breadcrumb-link {
    display: flex;
    align-items: center;
  }

  svg {
    padding-right: 4px;
  }
}
