@import "src/styles/breakpoints";
@import "src/styles/colors";

.wiki-details-page-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-height: 100%;
  margin: 0 20px;

  @include media-breakpoint-down(xs) {
    margin-left: 20px;
  }
  
  @include media-breakpoint-up(xl) {
    margin: 0 auto;
  }
  
  .wiki-search-container {
    margin: 0;
    margin-bottom: 16px;
  }

  .wiki-details-page-container-content {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }

  .wiki-details-content-history-container {
    width: 100%;
    padding-left: 0;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      padding-left: 16px;
    }
  }
}

.wiki-details-go-back-container {
  display: flex;
  justify-content: flex-start;
  max-width: 1200px;
  margin: 0 20px;
  padding: 16px 0;
  color: $ebony-clay;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  @include media-breakpoint-down(xs) {
    margin-left: 20px;
  }
  
  @include media-breakpoint-up(xl) {
    margin: 0 auto;
  }

  .wiki-details-go-back-arrow {
    transform: rotate(-90deg);
  }
}
