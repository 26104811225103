@import "src/styles/colors";

.wiki-search-result-tile-container {
  max-width: 1200px;
  min-height: 180px;
  margin: 0 0 20px 20px;
  padding: 20px;
  border-radius: 4px;
  background: $white;

  .wiki-search-result-tile-header {
    display: flex;
    justify-content: space-between;

    .wiki-search-result-tile-title {
      color: $dodger-blue;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .language-option {
      display: inline;
      color: $abbey;
      font-size: 13px;
      text-transform: uppercase;
    }
    
    .active {
      color: $dodger-blue;
      cursor: pointer;
    }
  }

  .last-edit {
    text-transform: uppercase;
  }
}
