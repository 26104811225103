@import "src/styles/breakpoints";
@import "src/styles/colors";

.page-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;

  @include media-breakpoint-up(xl) {
    padding: 16px 0;
  }
}

.bookmarks-container {
  margin-top: 16px;
  border-collapse: collapse;
  border: 1px solid $border-grey;
  border-radius: 4px;
  background: $white;

  .chart-title {
    border-bottom: 1px solid $border-grey;
  }
}

.table-and-events-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  .events-container {
    flex: 1;
    margin-top: 16px;
    border: 1px solid $border-grey;
    border-radius: 4px;
    background: $white;
  }

  .dashboard-table {
    flex: 2;
    flex-wrap: wrap;
    margin-top: 16px;
    overflow: hidden;
    border: 1px solid $border-grey;
    border-radius: 4px;
    background: $white;

    @include media-breakpoint-up(sm) {
      margin-right: 16px;
    }

    .dashboard-table-container {
      @include media-breakpoint-down(sm) {
        padding: 0 22px 16px;
      }

      thead tr {
        border: none;
      }

      tr {
        border: none;
        @include media-breakpoint-up(sm) {
          border-bottom: 1px solid $athens-gray;
        }
      }

      tbody {
        .ivu-table-row:last-of-type {
          border-bottom: 0 !important;
        }
      }
    }
  }
}

.holidays-container {
  margin-top: 16px;
  border: 1px solid $border-grey;
  border-radius: 4px;
  background: $white;
  font-size: 12px;

  .absences-spinner-container {
    margin: 2rem;
    text-align: center;
  }

  .absence-dots-container {
    display: inline-flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 5px;
    margin: auto 10px;

    .dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;

      &-green {
        background-color: $pastel-green;
      }

      &-gray {
        background-color: $storm-grey;
      }

      &-yellow {
        background-color: $ajjaj-yellow;
      }

      &-red {
        background-color: $radical-red;
      }
    }
  }

  .absence-row-text {
    &-green {
      color: $pastel-green;
      font-weight: 700;
      text-align: center;
    }

    &-red {
      color: $radical-red;
      font-weight: 700;
      text-align: center;
    }

    &-gray {
      color: $grey-light;
      font-weight: 700;
      text-align: center;
    }

    &-yellow {
      color: $ajjaj-yellow;
      font-weight: 700;
      text-align: center;
    }
  }

  .absence-summary-container {
    padding: 0 20px 22px;

    @include media-breakpoint-down(sm) {
      padding: 0 22px 16px;
    }
  }

  .absence-summary-block {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 2rem 0;

    p {
      margin: 0;
    }
  }

  .absence-summary-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin: 1rem 0;
  }

  .text-uppercase {
    text-transform: uppercase;
  }
}

.dashboard-upper-charts-container {
  max-width: 1200px;
  @include media-breakpoint-up(sm) {
    display: flex;
  }
}
