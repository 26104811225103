.header-profile-pic {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  overflow: hidden;
  border-radius: 100%;
}

.header-profile-container {
  height: 60px;
  margin: 0 16px;
  cursor: pointer;
}

.ant-dropdown-link {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 60px;
  font-family: Lato,sans-serif;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.header-profile-button, html[type="button"] {
  -webkit-appearance: none!important;

  &:focus {
    outline: none!important;
  }
}
