@import "src/styles/breakpoints";
@import "src/styles/colors";

/* stylelint-disable max-nesting-depth */

.table-actions {
  display: inline-flex;

  svg {
    margin-right: 0!important;
  }
}

.custom-table.ivu-table-wrapper {
  flex-wrap: wrap;
  border: none;

  &.has-status {
    .ivu-table-header {
      th:first-child {
        span {
          display: none;
        }
      }
    }
  }

  .ivu-table {
    width: calc(100% - 32px);

    table {
      width: 100%;
      table-layout: auto !important;
      @include media-breakpoint-down(xs) {
        width: calc(100vw - 54px)!important;
      }

    }

    &:before, &:after {
      display: none;
    }

    td {
      border-bottom: none !important;
    }
  }

  .ivu-table-header {
    th {
      height: 35px;
      border-bottom-color: $athens-gray;
      background-color: transparent;
      color: $malibu;
      font-size: 10px;
      letter-spacing: .8px;
      text-align: center;
      text-transform: uppercase;
    }

    th:first-child span {
      display: block!important;
    }

    @include media-breakpoint-down(xs) {
      &:not(.custom) {
        position: absolute;
      }

      top: -9999px;
      left: -9999px;
    }
  }

  .ivu-table-body {
    tr {
      td {
        height: 42px;
        border-bottom: none !important;
        font-size: 12px;
        text-align: center;

        @include media-breakpoint-down(xs) {
          align-items: center;
          width: calc(100vw - 64px)!important;
          overflow: hidden;

          &:not(.custom) {
            display: flex;
          }

          &.custom .ivu-table-cell {
            text-align: center;
            
            .cell-wrap {
              justify-content: center;
            }
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .ivu-table-cell {
          @include media-breakpoint-down(xs) {
            width: 100%;
            margin: 0 auto;
            text-align: left;

            .cell-wrap {
              @include media-breakpoint-down(xs) {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
          }

          .column-head-title {
            display: none;
            margin-right: 15px;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;

            @include media-breakpoint-down(xs) {
              display: inline-block;
              width: 100%;
              max-width: 130px;
              color: $malibu;
            }
            
            &.contrib {
              color: $turquoise-blue!important;
            }
          }

          .btn-action {
            width: 25px;
            height: 25px;
            margin: 0 3px;
            padding: 0;
            border: none;
            border-radius: 100%;
            background: $apricot;
            color: $white;
            font-size: 14px;

            &-edit {
              background: $cream-can;
            }
          }

          .disabled {
            background: $dark-grey;
          }
        }
      }

      .expand-icon {
        @include media-breakpoint-up(sm) {
          display: none;
        }
      }

      &.ivu-table-row {
        & > td {
          &:last-child {
            @include media-breakpoint-down(xs) {
              border-bottom: 1px solid $athens-gray !important;
            }
          }
        }

        &.active {
          .expand-icon {
            .ivu-icon {
              transform: rotate(180deg);
            }
          }
        }
      }

      &.expand-row {
        display: none;

        &.active {
          display: block;
        }

        .ivu-table-cell {
          text-align: center;

          .cell-wrap {
            display: block;
          }
        }

        tr {
          th {
            border-bottom: none;
            font-size: 10px;
            text-transform: uppercase;

            & .ivu-table-cell {
              padding-right: 0;
              padding-left: 0;
            }
          }

          td {
            display: table-cell;
            width: auto;
          }
        }
      }
    }
  }

  .ivu-icon {
    font-size: 16px;

    &.pending {
      color: $cream-can;
    }

    &.published {
      color: $pastel-green;
    }

    &.cancelled, &.denied {
      color: $apricot;
    }
  }
}

/* stylelint-enable */
