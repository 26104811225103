@import "src/styles/colors";

.search-result-header-container {
  max-width: 1200px;
  margin: 20px 0 20px 20px;
  padding: 20px;
  animation: fadein 2s;
  border-radius: 4px;
  background: $white;

  .search-result-header-label {
    margin-right: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.ant-tag {
  height: 22px;
  margin: 10px!important;
  margin-left: 0!important;
  padding: 3px 14px!important;
  border-radius: 30px!important;
  font-size: 10px!important;
  font-weight: 700;
  text-transform: uppercase;
}
