@import "src/styles/breakpoints";

.basic-search-container {
  @include media-breakpoint-up(sm) {
    width: 33%;
    padding-right: 40px;
  }
  
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  padding: 20px;

  .basic-search-title {
    font-size: 15px;
  }

  .basic-search {
    position: relative;
    width: 100%;

    .basic-search-placeholder {
      position: absolute;
      left: 2px;

      &.none {
        display: none;
      }
    }

    .basic-search-placeholder-question {
      position: absolute;
      top: 2px;
      right: 8px;
    }

    .basic-search-placeholder-close {
      position: absolute;
      top: 2px;
      right: 8px;
      cursor: pointer;
    }

    .basic-search-input {
      display: flex;
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid #000;
      border-radius: 0!important;
      font-size: 15px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        padding-left: 6px;
        font-size: 11px;
      }
    }
  }
}
